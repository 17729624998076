.info-container{
    width: 100%;
    height: 300px;
    background-color: white;
    color: black;
    margin-bottom: 100vh;
}
.info-title{
    justify-content: center;
    align-items: center ;
    height: 100px;
    padding: 50px 30px 0 10px;
    text-align: center;
}

.info{
    display: flex;
    justify-content: center;
    align-items: center ;
    height: 100px;
    padding: 30px 30px 30px 20px;
}
@media only screen and (max-width:960px){
    .info-container{
        height: 200px;
        width: 100%;
    }
    .info-title{
        padding: 30px 30px 30px 20px;

    }
    .info > h1{
        text-align: center;
        font-size: 20px;

    }
    .info > h4{
        text-align: center;
        font-size: 12px;

    }
}
