.about-container {
    display: flex;
    background-color: black;
    color: white;
    height: 70vh;
    padding: 100px 0 100px 0;
  }
  
  .about-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px 0 40px;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    transition-timing-function: linear;
  }

  .about-desc:hover{
    background-image: linear-gradient(90deg,#f61b10,#ef0963);
    
  }

 
  .service{
    padding: 30px 35px;
    z-index: 2;
    border-radius: 10px;
  }

  .about-desc-title > h3 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px;
    padding-top: 30px;
  }
  
  .about-desc > p {
    line-height: 1.5;
  }

  .about-desc .icon {
    font-size: 54px;
    font-weight: 400;
    margin-bottom: 23px;
    display: inline-flex;
    color: #f9004d;
  }
  
  .about-img {
    width: 45%;
    height: 150%;
    display: flex;
    align-self: center;
    overflow: hidden;

  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
 
  @media only screen and (max-width:960px){
      .about-container{
          flex-direction: column;
          padding: 0;
          height: auto;
      }
      .about-desc{
          width: 90%;
          margin: 0 auto;
          padding: 40px 20px 50px 20px;
      }
      .about-desc > h3{
          width: 20%;
          margin-bottom: 50px;
      }
      .about-img{
          width: 100%;
          margin-bottom: 50px;
      }
      .about-img > img{
          width: 100%;
      }
  }

  