.navbar{
    position: fixed;
    top:0;
    background-color: transparent;
    width: 100%;
    height: 80px;
    padding-left: 50px;
    font-size: 20px;
    z-index: 1;

}

.scrolling {
    background-color: black;
    transition: all 0.5s ease;
}
.navbar-logo{
    height: 80px;
    line-height: 80px;
    color: white;
    cursor: pointer;

}

.navbar-logo > img{
    margin-top: 10px;
    height: 60px;
}